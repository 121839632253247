"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DcBaseComponent } from "@deleteagency/dc";
import { PageLocker } from "project/general/js/page-locker";
const ACTIVE_CLASS = "is-active";
const VISIBLE_CLASS = "is-visible";
const ATTRIBUTE_SECOND_LEVEL_ID = "data-second-level-menu-id";
const ATTRIBUTE_THIRD_LEVEL_ID = "data-third-level-menu-id";
export default class NavigationComponent extends DcBaseComponent {
  constructor(...args) {
    super(...args);
    __publicField(this, "showSubMenu", (button, menus, menuAttribute) => {
      button.classList.add(ACTIVE_CLASS);
      this.thirdLevelMenus.forEach((menu2) => menu2.classList.remove(VISIBLE_CLASS));
      const menu = [...menus].find((_menu) => _menu.getAttribute(menuAttribute) === button.getAttribute("data-button-open-menu-id"));
      menu.classList.add(VISIBLE_CLASS);
    });
    __publicField(this, "initCloseSubMenuButton", (menu, callback) => {
      if (!menu)
        return;
      const backButton = menu.querySelector("button[data-navigation-back-button]");
      if (!backButton)
        return;
      backButton.addEventListener("click", () => {
        menu.classList.remove(VISIBLE_CLASS);
        if (typeof callback === "function")
          callback();
      });
    });
    __publicField(this, "openMenu", () => {
      this.pageLocker.lock();
      this.refs.navigation.classList.add(VISIBLE_CLASS);
    });
    __publicField(this, "closeMenu", () => {
      this.pageLocker.unlock();
      this.refs.navigation.classList.remove(VISIBLE_CLASS);
      this.element.classList.remove(ACTIVE_CLASS);
      setTimeout(() => {
        this.firstLevelMenu.classList.remove(ACTIVE_CLASS);
        this.secondLevelMenus.forEach((menu) => menu.classList.remove(ACTIVE_CLASS));
        this.secondLevelMenus.forEach((menu) => menu.classList.remove(VISIBLE_CLASS));
        this.thirdLevelMenus.forEach((menu) => menu.classList.remove(VISIBLE_CLASS));
      }, 350);
    });
    this.pageLocker = new PageLocker();
    this.pageLocker.setOptions({ target: document.body });
    this.isSticky = false;
  }
  static getNamespace() {
    return "navigation";
  }
  static getRequiredRefs() {
    return [
      "menuButton",
      "navigation",
      "closeButtons"
    ];
  }
  onInit() {
    this.refs.navigation.removeAttribute("style");
    this.addListener(document, "keydown", (event) => {
      if (event.keyCode === 27) {
        this.closeMenu();
      }
    });
    this.addListener(this.refs.menuButton, "click", this.openMenu);
    this.refs.closeButtons.forEach((button) => {
      this.addListener(button, "click", this.closeMenu);
    });
    this.firstLevelMenu = this.refs.navigation.querySelector("[data-first-level-menu-id]");
    this.secondLevelMenus = this.refs.navigation.querySelectorAll(`[${ATTRIBUTE_SECOND_LEVEL_ID}]`);
    this.thirdLevelMenus = this.refs.navigation.querySelectorAll(`[${ATTRIBUTE_THIRD_LEVEL_ID}]`);
    this.secondLevelMenus.forEach((secondLevelMenu, index) => {
      this.initCloseSubMenuButton(secondLevelMenu, () => {
        this.firstLevelMenu.classList.remove(ACTIVE_CLASS);
        this.refs.navigation.classList.remove(ACTIVE_CLASS);
      });
      const secondLevelButtons = secondLevelMenu.querySelectorAll("button[data-button-open-menu-id]");
      if (secondLevelButtons.length === 0)
        return;
      secondLevelButtons.forEach((button) => {
        button.addEventListener("click", () => {
          secondLevelButtons.forEach((_button) => _button.classList.remove(ACTIVE_CLASS));
          this.secondLevelMenus[index].classList.add(ACTIVE_CLASS);
          this.refs.navigation.classList.add(ACTIVE_CLASS);
          this.showSubMenu(button, this.thirdLevelMenus, ATTRIBUTE_THIRD_LEVEL_ID);
        });
      });
    });
    this.thirdLevelMenus.forEach((menu) => {
      this.initCloseSubMenuButton(menu, () => this.secondLevelMenus.forEach((_menu) => _menu.classList.remove(ACTIVE_CLASS)));
    });
    const firstLevelButtons = this.firstLevelMenu.querySelectorAll("[data-button-open-menu-id]");
    if (firstLevelButtons.length === 0)
      return;
    firstLevelButtons.forEach((button) => {
      button.addEventListener("click", () => {
        firstLevelButtons.forEach((_button) => _button.classList.remove(ACTIVE_CLASS));
        this.firstLevelMenu.classList.add(ACTIVE_CLASS);
        this.refs.navigation.classList.add(ACTIVE_CLASS);
        this.secondLevelMenus.forEach((menu) => menu.classList.remove(VISIBLE_CLASS));
        this.secondLevelMenus.forEach((menu) => menu.classList.remove(ACTIVE_CLASS));
        this.showSubMenu(button, this.secondLevelMenus, ATTRIBUTE_SECOND_LEVEL_ID);
      });
    });
  }
  onDestroy() {
    this.pageLocker = null;
    this.firstLevelMenu = null;
    this.secondLevelMenus = null;
    this.thirdLevelMenus = null;
  }
}
